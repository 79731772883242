import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Button from "../../../../Button"

import Shield from "../../../../../assets/img/shield.svg"

import "./intro.scss"

export default () => (
  <StaticQuery
    query={graphql`
      query {
        HeroImage: file(relativePath: { eq: "industries/education.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 4000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        OrganizationsMobile: file(
          relativePath: { eq: "industries/organizations_mobile.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 2000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        Organizations: file(
          relativePath: { eq: "industries/organizations.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 2000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={(data) => (
      <section className="educationPage__intro">
        <Img
          className="educationPage__heroImage"
          fluid={data.HeroImage.childImageSharp.fluid}
          alt="Hero image"
        />
        <div className="container">
          <h1>
            <span>Implement Software Licensing</span> in your Learning App:
          </h1>
          <p>
            With LicenseSpring, you can easily assign licenses of your
            application to academic institutions or directly to students.
          </p>
          <ul>
            <li>
              Grant temporary and perpetual licenses for your educational
              content
            </li>
            <li>License your application for use in an offline environment</li>
            <li>Issue site licenses for users on a network</li>
          </ul>
          <Button
            label="Open trial account"
            size="big"
            shadow
            color="blue"
            disclaimer
            to="/get-started/"
          />
          <div>
            <p>
              <img src={Shield} alt="Shield icon" /> Trusted by hundreds of
              organizations including:
            </p>
          </div>
          <Img
            className="organizationsLogos organizationsLogos--mobile"
            fluid={data.OrganizationsMobile.childImageSharp.fluid}
            alt="Organizations logos"
          />
          <Img
            className="organizationsLogos"
            fluid={data.Organizations.childImageSharp.fluid}
            alt="Organizations logos"
          />

          <h2>School-based and Student-Based licensing</h2>
          <p>
            Many educational applications are licensed directly to all students
            in a specific classroom for the duration of an academic term, with
            the option to extend the license. Implementing standard educational
            licensing models can easily be achieved with LicenseSpring
          </p>
          <ul>
            <li>
              Assign licenses to students in a class for your educational
              application
            </li>
            <li>
              Add classroom managers per school or per classroom assign licenses
            </li>
          </ul>
          <h2>Grant Academic Licenses</h2>
          <p>
            Require an email address from an educational institution, or
            manually verify that a person really is a student before granting
            them an academic license. You can use LicenseSpring to distinguish
            between different types of licenses issued such as normal end user
            licenses or Academic licenses. You can also control which modules of
            your software are available to the end user depending on the type of
            license issued.
          </p>
        </div>
      </section>
    )}
  />
)
