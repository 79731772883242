import React from 'react'

import Layout from '../../../components/Layout'
import SEO from "../../../components/Seo"
import Apps from '../../../components/Apps'
import TrustedBlock from '../../../components/TrustedBlock'
import BottomBlock from '../../../components/BottomBlock'

import Intro from '../../../components/Pages/Industries/Education/Intro'

import './education.scss'

export default ({location}) =>
    <Layout className="page page--industry educationPage">
        <SEO
            title="LicenseSpring: Licensing for Education Software"
            description="License your software per student, term, and school. Learn how LicenseSpring makes it easy to license your educational software"
            path={location.pathname}
        />
        <Intro />
        <div className="container">
            <Apps />
        </div>
        
        <TrustedBlock />
        <BottomBlock pricingLink content="See why EdTech embraces LicenseSpring by signing up for a free trial" />
    </Layout>